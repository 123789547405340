import * as React from 'react';
import { filterExactOrSimilarProducts } from './helpers';
import { style, classes } from 'typestyle';
import { StoreContext } from '../../../../services/store';
import { Product } from '../Product';
import { UserName } from '../UserName';

export function Details() {
  const context = React.useContext(StoreContext);
  const { post: postUrl, products } = context.post!;

  const {
    shop_this_look: shopThisLook,
    display_exact_products_only: displayExactProducts,
    display_similar_products_only: displaySimilarProducts,
    has_exact_products: hasExactProducts,
    has_similar_products: hasSimilarProducts,
    max_exact_products_displayed: maxExactProducts,
    max_similar_products_displayed: maxSimilarProducts,
  } = context.data.settings;

  const [currentPostUrl, setCurrentPostUrl] = React.useState(postUrl);
  const containerRef = React.createRef<HTMLDivElement>();
  const [headerWidth, setHeaderWidth] = React.useState(containerRef.current?.offsetWidth ?? 425);

  const productRefs = React.useRef<(HTMLDivElement | null)[]>([]);

  // sort products into exact and similar arrays
  const { exact, similar } = context.post!.productsLinked;

  const { exactProducts, similarProducts } = filterExactOrSimilarProducts(maxExactProducts, maxSimilarProducts, products, exact, similar);

  // Update the maximum number of product columns,
  // and adjust the display depending on the number of current products linked
  let maxProductColumns = 2;
  let productContainerStyle;

  if (shopThisLook && products && products.length > 0) {
    if (context.data.settings.shop_this_look_max_columns) {
      maxProductColumns = context.data.settings.shop_this_look_max_columns;
    }
    const columnCount = products.length < maxProductColumns ? products.length : maxProductColumns;
    productContainerStyle = style({
      display: 'grid',
      gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
    });
  }

  React.useEffect(() => {
    productRefs.current = productRefs.current.slice(0, products?.length);
  }, [products]);

  React.useEffect(() => {
    if (currentPostUrl !== postUrl) {
      // reset component when we change post
      setCurrentPostUrl(postUrl);
    }
  });

  React.useEffect(() => {
    setHeaderWidth((containerRef.current?.offsetWidth ?? 440) - 15);
  }, [containerRef.current, containerRef]);

  const headerStyle = context.isMobile
    ? style({
        position: 'relative',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.8)',
        backdropFilter: 'blur(5px)',
      })
    : style({
        position: 'relative',
        backgroundColor: 'rgba(255,255,255,0.8)',
        backdropFilter: 'blur(5px)',
        padding: 9,
      });

  const showExactProducts =
    shopThisLook &&
    ((exactProducts.length > 0 && !displaySimilarProducts) || (displayExactProducts && exactProducts.length > 0 && !displaySimilarProducts));

  const showSimilarProducts =
    shopThisLook &&
    ((similarProducts.length > 0 && !displayExactProducts) || (displaySimilarProducts && similarProducts.length > 0 && !displayExactProducts));

  return (
    <div
      className={classes(detailsClass(context.isMobile), `${context.config.classPrefix}-postviewer-details`)}
      style={{
        ...(context.isMobile ? mobileStyle : desktopStyle),
      }}
    >
      <div style={containerStyle(context.isMobile)} ref={containerRef}>
        {context.config.displayCustomShopThisLook ? (
          context.config.displayCustomShopThisLook({
            react: React,
            products,
            media: context.post!,
            isMobile: context.isMobile,
            context: context,
          })
        ) : (
          <>
            <div className={fullCommuStyle}>{!(shopThisLook && products && products.length) && <UserName />}</div>
            {shopThisLook && products && products.length > 0 && (
              <>
                <div className={headerStyle}>
                  <div
                    className={classes(
                      titleClass,
                      {
                        [mobileTitleClass]: context.isMobile,
                      },
                      `${context.config.classPrefix}-postviewer-details-products`
                    )}
                  >
                    {context.config.shopThisLookText}
                  </div>
                  <UserName />
                </div>
                <div
                  className={classes(
                    context.isMobile ? productContainerMobileStyle : productContainerStyle,
                    `${context.config.classPrefix}-postviewer-details-products`
                  )}
                >
                  {showExactProducts &&
                    exactProducts.map((p, i) => (
                      <div key={p.id} className={context.isMobile ? mobileProductLineStyle : productLineStyle}>
                        <Product
                          ref={(ref) => {
                            productRefs.current[i] = ref;
                          }}
                          isVisible={true}
                          product={p}
                          parentMedia={context.post!}
                        />
                      </div>
                    ))}
                </div>

                {showSimilarProducts ? (
                  <>
                    <div
                      className={classes(
                        underTitleClass,
                        {
                          [mobileTitleClass]: context.isMobile,
                        },
                        `${context.config.classPrefix}-postviewer-details-products`
                      )}
                    >
                      {context.config.moreLikeThisText}
                    </div>
                    <div
                      className={classes(
                        context.isMobile ? productContainerMobileStyle : productContainerStyle,
                        `${context.config.classPrefix}-postviewer-details-products`
                      )}
                    >
                      {similarProducts.map((p, i) => (
                        <div key={p.id} className={context.isMobile ? mobileProductLineStyle : productLineStyle}>
                          <Product
                            ref={(ref) => {
                              productRefs.current[i] = ref;
                            }}
                            isVisible={true}
                            product={p}
                            parentMedia={context.post!}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const fullCommuStyle = style({
  marginTop: 7,
  padding: '0 5px',
  position: 'relative',
  marginBottom: 5,
});

const detailsClass = (isMobile: boolean = false): React.CSSProperties => ({
  padding: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: 5,
  width: isMobile ? '50%' : '100%',
});

const mobileStyle: React.CSSProperties = {
  position: 'relative',
  padding: 10,
};

const desktopStyle: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  width: '50%',
};

const containerStyle = (isMobile: boolean = false): React.CSSProperties => ({
  fontFamily: "'Be Vietnam Pro', sans-serif",
  color: '#231F20',
  letterSpacing: 0,
  fontSize: 14,
  wordBreak: 'break-word',
  width: '100%',
  overflowY: isMobile ? 'hidden' : 'auto',
});

const productContainerMobileStyle = style({
  display: 'grid',
  gridAutoFlow: 'column',
});

const productLineStyle = style({
  margin: '10px 10px 0px 0',
  width: 'auto',
});

const mobileProductLineStyle = style({
  margin: '10px 10px 15px 0',
});

const titleClass = style({
  justifyContent: 'left',
  display: 'flex',
  fontWeight: 300,
  fontSize: 21,
  all: 'revert',
  paddingBottom: 9,
});

const underTitleClass = style({
  justifyContent: 'left',
  display: 'flex',
  fontWeight: 300,
  fontSize: 16,
  all: 'revert',
  padding: 9,
});

const mobileTitleClass = style({
  margin: 0,
});
