import ReactGA from 'react-ga4';
import { Direction, EventMapKeys } from '../types';

export interface GACustomMap {
  event_widget_id: string;
  event_post_id?: string;
  event_product_id?: string;
  event_direction?: Direction;
  event_position?: number;
}

export class GAnalytics {
  constructor(private GA_KEY: string) {
    this.init(GA_KEY);
  };

  public init(GA_KEY: string) {
    ReactGA.initialize(
      GA_KEY,
      {
        gtagOptions: {
          custom_map: {
            event_widget_id: 'event_widget_id',
            event_post_id: 'event_post_id',
            event_product_id: 'event_product_id',
          }
        },
        gaOptions: {
          cookieFlags: 'SameSite=None; Secure',
        },
      }
    );
  };

  public trackEvent<K extends EventMapKeys>(params: {
    eventName: K,
    data: GACustomMap
  }) {
    const { eventName, data } = params;
    ReactGA.event(eventName, {
      event_category: 'Widget Interaction',
      event_widget_id: data.event_widget_id,
      event_post_id: data.event_post_id,
      event_product_id: data.event_product_id,
    });
  };
}
